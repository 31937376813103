<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        scrollable
        max-width="300px"
        @click:outside="$emit('closeDialog', []); clearSelectedOptions()"
        @keydown.esc="$emit('closeDialog', []); clearSelectedOptions()"
    >
      <v-card>
        <v-card-title>Select folder to remove</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px;">
          <v-radio-group v-model="selectedFolder">
          <v-radio
              v-for="(item) in options"
              :key="item"
              :label="item"
              :value="item"
          ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <div class="w-100-p d-flex justify-end">
            <v-btn class="mr-3"
                color="primary"
                text
                @click="$emit('closeDialog', null); clearSelectedOptions()"
            >
              Close
            </v-btn>
            <v-btn
                color="primary"
                text
                @click="$emit('closeDialog', selectedFolder); clearSelectedOptions()"
            >
              Select
            </v-btn>
          </div>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: 'ClearFolderDialog',
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    }
  },
  data: function() {
    return {
      selectedFolder: null
    }
  },
  methods: {
    clearSelectedOptions() {
      this.selectedFolder = null;
    }
  }
}
</script>
