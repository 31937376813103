import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const storeLoggerPlugin = store => {
    // called when the store is initialized
    store.subscribe((mutation, state) => {
        const {type, payload} = mutation;
        // called after every mutation.
        // The mutation comes in the format of `{ type, payload }`.
        console.groupCollapsed(`%c ${type}`, 'text-transform: uppercase');
        console.log('state: ', state);
        console.log('payload: ', payload);
        console.groupEnd();
    })
}

export default new Vuex.Store({
    state: {
        apiPending: false,
        concordSearch: '',
        concordDisplayBySearch: 'Word',
        keywordFolderSearch: '',
        keywordFileSearch: '',
        collocationFieldSearch: 'All',
        collocationCenterWordSearch: '',
        folders: {},
        displayStatisticsBy: 'All',
        displayStatisticsByFile: 'All',

    },
    actions: {
        setApiPending({commit}, data) {
            commit('SET_API_PENDING', data)
        },
        setConcordDisplayBySearch({commit}, data) {
            commit('SET_CONCORD_DISPLAY_BY_SEARCH', data)
        },
        setConcordSearch({commit}, data) {
            commit('SET_CONCORD_SEARCH', data)
        },
        setKeywordFolderSearch({commit}, data) {
            commit('SET_KEYWORD_FOLDER_SEARCH', data)
        },
        setKeywordFileSearch({commit}, data) {
            commit('SET_KEYWORD_FILE_SEARCH', data)
        },
        setCollocationFieldSearch({commit}, data) {
            commit('SET_COLLOCATION_FIELD_SEARCH', data)
        },
        setCollocationCenterWordSearch({commit}, data) {
            commit('SET_COLLOCATION_CENTER_WORD_SEARCH', data)
        },
        clearSearchData({commit}) {
            commit('CLEAR_SEARCH_DATA')
        },
        clearFolder({commit}, data) {
            commit('CLEAR_FOLDER', data);
        },

        addFolders({commit}, data) {
            commit('ADD_FOLDERS', data);
        },
        updateDisplayStatisticsBy({commit}, data) {
            commit('UPDATE_DISPLAY_STATISTICS_BY', data);
        },
        updateDisplayStatisticsByFile({commit}, data) {
            commit('UPDATE_DISPLAY_STATISTICS_BY_FILE', data);
        },
    },
    mutations: {
        SET_API_PENDING(state, data) {
            state.apiPending = data;
        },
        SET_CONCORD_DISPLAY_BY_SEARCH(state, data) {
            state.concordDisplayBySearch = data;
        },
        SET_CONCORD_SEARCH(state, data) {
            state.concordSearch = data;
        },
        SET_KEYWORD_FOLDER_SEARCH(state, data) {
            state.keywordFolderSearch = data;
        },
        SET_KEYWORD_FILE_SEARCH(state, data) {
            state.keywordFileSearch = data;
        },
        SET_COLLOCATION_FIELD_SEARCH(state, data) {
            state.collocationFieldSearch = data;
        },
        SET_COLLOCATION_CENTER_WORD_SEARCH(state, data) {
            state.collocationCenterWordSearch = data;
        },
        CLEAR_SEARCH_DATA(state) {
            state.concordSearch = '';
            state.concordDisplayBySearch = 'Word';
            state.keywordFolderSearch = '';
            state.keywordFileSearch = '';
            state.collocationFieldSearch = 'All';
            state.collocationCenterWordSearch = '';
            state.collacationFields = [];
        },
        CLEAR_FOLDER(state, selectedFolders) {
            selectedFolders.forEach((folder) => {
                state.fileContent = state.fileContent.filter((content) => content.directory !== folder);
                state.data = state.data.filter((content) => content.directory !== folder);
                state.numberOfWords = state.data.length;
            });
        },


        ADD_FOLDERS(state, folders) {
            if (folders) {
                state.folders = folders;
            }
        },
        UPDATE_DISPLAY_STATISTICS_BY(state, data) {
            state.displayStatisticsBy = data;
        },
        UPDATE_DISPLAY_STATISTICS_BY_FILE(state, data) {
            state.displayStatisticsByFile = data;
        },
    },
    getters: {
        getApiPending: state => {
            return state.apiPending
        },
        getConcordSearch: state => {
            return state.concordSearch
        },
        getConcordDisplayBySearch: state => {
            return state.concordDisplayBySearch
        },
        getKeywordFolderSearch: state => {
            return state.keywordFolderSearch
        },
        getKeywordFileSearch: state => {
            return state.keywordFileSearch
        },
        getCollocationFieldSearch: state => {
            return state.collocationFieldSearch
        },
        getCollocationCenterWordSearch: state => {
            return state.collocationCenterWordSearch
        },


        getFolders: state => {
            return state.folders
        },
        getDisplayStatisticsBy: state => {
            return state.displayStatisticsBy
        },
        getDisplayStatisticsByFile: state => {
            return state.displayStatisticsByFile
        },
    },
    modules: {},
    plugins: [storeLoggerPlugin]
});
