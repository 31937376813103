<template>
  <v-navigation-drawer
    color="primary"
    dark
    app
    left
    v-model="drawerState"
    absolute
    @input="updateDrawerOpenState($event)"
  >
    <v-list-item>
      <v-list-item-content class="h-64">
        <v-list-item-title class="text-h6">
          CLC WORD ANALYSIS
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense nav>
      <v-list-item
        v-for="item in items"
        :key="item.title"
        :to="item.dest"
        class="sidebar-links"
        link
      >
        <v-list-item-icon>
          <v-icon class="v-icon-grey">{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="text-grey">{{
            item.title
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    drawer: Boolean,
  },
  watch: {
    drawer: function (newVal) {
      this.drawerState = newVal;
    },
  },
  data() {
    return {
      items: [
        { title: "Statistics", icon: "mdi-chart-bar", dest: "/statistics" },
        { title: "Keywords", icon: "mdi-format-text", dest: "/keywords" },
        { title: "Concord", icon: "mdi-file-find", dest: "/concord" },
        { title: "Collocations", icon: "mdi-help-box", dest: "/collocations" },
        { title: "Helps", icon: "mdi-help", dest: "/help" },
      ],
      right: null,
      drawerState: this.drawer,
    };
  },
  methods: {
    updateDrawerOpenState(value) {
      this.$emit("updateDrawerState", value);
    },
  },
};
</script>
