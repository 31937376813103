export default {
    name: 'CustomSort',
    wordMultiPosSort(items, index, isDesc) {
        items.sort((a, b) => {
            if (index.includes("poses")) {
                const firstLength = a.poses.split(',').length;
                const secondLength = b.poses.split(',').length;
                if (!isDesc[0]) {
                    return firstLength < secondLength ? -1 : 1;
                } else {
                    return firstLength > secondLength ? -1 : 1;
                }
            } else {
                if (!isDesc[0]) {
                    return a[index] < b[index] ? -1 : 1;
                } else {
                    return b[index] < a[index] ? -1 : 1;
                }
            }
        });
        return items;
    }
}
