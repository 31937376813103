export default {
    name: 'CustomFilter',
    replaceSpaceWithDashFilter (value, search) {
        return value !== null &&
            search !== null &&
            typeof value === 'string' &&
            value.toString().toLowerCase().replace(/_/g, " ") ===
                search.toLowerCase().replace(/_/g, " ")
    },
}
