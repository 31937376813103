<template>
  <div class="text-center">
    <v-btn
        :disabled="dialog"
        :loading="dialog"
        class="white--text"
        color="purple darken-2"
        @click="dialog = true"
        style="display: none"
    ></v-btn>
    <v-dialog
        v-model="dialog"
        persistent
        width="300"
        hide-overlay
        content-class="uploading-dialog"
    >
      <v-card>
        <v-card-text class="pa-3">
          <div class="d-flex justify-space-between align-center">
            <span v-if="hasPercent">Uploading {{numberOfFilesToUpload}} files</span>
            <span v-else>Getting tokens from files</span>
            <v-progress-circular
                :size="40"
                :width="5"
                :value="currentUploadingFileNumber / numberOfFilesToUpload * 100"
                :indeterminate="!hasPercent"
                color="primary"
            >
              {{hasPercent ? currentUploadingFileNumber : null}}
            </v-progress-circular>
          </div>

        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    numberOfFilesToUpload: {
      type: Number,
      required: true,
    },
    currentUploadingFileNumber: {
      type: Number,
      required: true,
    },
    hasPercent: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      // dialog: false
    }
  }
}
</script>
