import CustomFilter from "./custom-filter";
import CustomSort from "./custom-sort";

const helperManager = {
  CustomFilter,
  CustomSort,
};

export default {
  install(Vue) {
    Vue.prototype.$helper = helperManager;
  },
};
