<template>
  <v-app-bar class="bg-white" app>
    <div class="d-flex align-center justify-space-between w-100-p">
      <!-- Toggle Sidebar -->
      <v-app-bar-nav-icon @click="$emit('toggleSidebar')"></v-app-bar-nav-icon>
      <!-- End Toggle Sidebar -->

      <!-- Upload Directories/Hidden -->

      <div>
        <input
            type="file"
            ref="newNormalFileInput"
            webkitdirectory
            directory
            multiple
            hidden
            @change="uploadNewNormalFolders($event)"
        />

        <input
            type="file"
            ref="newTokenziedFileInput"
            webkitdirectory
            directory
            multiple
            hidden
            @change="uploadNewTokenizedFolders($event)"
        />
      </div>

      <!-- End Upload Directories/Hidden -->

      <div>
        <!-- View Result -->
        <span v-if="folderOptions.length > 0" class="mr-3">
           {{ folderOptions.length }} folders
        </span>
        <!-- End View Result -->

        <!-- Clear Result -->
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                class="mr-3"
                dark
                v-bind="attrs"
                v-on="on"
                :disabled="folderOptions.length === 0 || getApiPending"
                @click="clearFolder"
            >
              Clear
            </v-btn>
          </template>
        </v-menu>
        <!-- End Clear Result -->

        <!-- Upload File Button -->
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                :disabled="getApiPending"
            >
              Upload
            </v-btn>
          </template>
          <v-list>
            <v-list-item
                @click="openFileInput(item.key)"
                v-for="(item, index) in fileMenuItems"
                :key="index"
            >
              <v-list-item-title>
                <div>{{ item.title }}</div>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- End Upload File Button -->

      </div>
    </div>
    <ClearFolderDialog :dialog="showClearDialog"
                       :options="folderOptions"
                       @closeDialog="clearSelectedFolder($event)">
    </ClearFolderDialog>

    <FolderUploadProgressDialog
        :dialog="showUploadingDialog"
        :numberOfFilesToUpload="numberOfFilesToUpload"
        :currentUploadingFileNumber="currentUploadingFileNumber"
        :has-percent="hasPercent"
    >
    </FolderUploadProgressDialog>
  </v-app-bar>
</template>
<script>
import ClearFolderDialog from "../components/reuseable/clear-folder-dialog";
import FolderUploadProgressDialog from "../components/reuseable/folder-upload-progress-dialog";
import {mapGetters} from "vuex";

export default {
  components: {FolderUploadProgressDialog, ClearFolderDialog},
  created() {

    this.$api.preprocess.prepare()
        .then((res) => {
          if (res.status === 200 && res.data) {
            this.$store.dispatch('addFolders', res.data);
          }
          this.$store.dispatch('setApiPending', false);
        })
        .catch(() => {
          this.$store.dispatch('setApiPending', false);
        });
  },
  computed: {
    ...mapGetters([
      'getFolders',
      'getApiPending',
    ]),
  },
  watch: {
    getFolders(folders) {
      this.folderOptions = Object.keys(folders);
    }
  },
  data: function () {
    return {
      numberOfFiles: 0,
      fileInfos: [],
      showClearDialog: false,
      fileMenuItems: [
        {
          title: 'Upload New Normal Folders',
          key: 'uploadNewNormalFolders',
        },
        {
          title: 'Upload New Tokenized Folders',
          key: 'uploadNewTokenizedFolders',
        }
      ],
      uploadedFiles: {},
      folderOptions: [],
      snackbarText: '',
      maxFileSize: 104857600,
      filesForApi: [],
      showUploadingDialog: false,
      numberOfFilesToUpload: 0,
      currentUploadingFileNumber: 0,
      hasPercent: true
    }
  },
  methods: {
    uploadNewNormalFolders(event) {
      let files = event.target.files;
      this.handleNewNormalFiles(files);
    },

    handleNewNormalFiles(files) {
      this.filesForApi = [];
      const arrayOfFiles = Array.from(files);

      let contents = [];

      arrayOfFiles.forEach((file, index, array) => {
        if (this.isValidTextFile(file.name)) {
          let fileReader = new FileReader();

          fileReader.onload = (fileLoadedEvent) => {
            const folderSegments = file.webkitRelativePath.split("/");
            const parentFolder = folderSegments[folderSegments.length - 2];
            const fileContent = fileLoadedEvent.target.result;

            const indexOfTokenizedWord = fileContent.indexOf('Tokenize');
            let content;
            if (indexOfTokenizedWord > -1) {
              content = fileContent.slice(indexOfTokenizedWord + 9, fileContent.length);
            } else {
              content = fileContent;
            }

            content = content.replace(/(\r\n|\n|\r)/gm, ' ');
            contents.push(content);

            this.filesForApi.push({
              folder: parentFolder,
              files: [{
                filename: file.name,
              }]
            })
            if (index === array.length - 1) {
              this.getTokenFromFiles(contents);
            }
          };

          fileReader.readAsText(file, "UTF-8");
        }
      });
    },

    async getTokenFromFiles(contents) {
      this.hasPercent = false;
      this.showUploadingDialog = true;
      try {
        let res = await this.$api.preprocess.preprocess({content: contents});
        this.showUploadingDialog = false;
        if (res.status === 200 && res.data && res.data["success"]) {
          this.filesForApi = this.filesForApi.map((file, index) => {
            return {
              ...file,
              files: [{
                filename: file.files[0].filename,
                content: res.data.data && res.data.data[index] ? res.data.data[index].join('\n/') : ''
              }]
            }
          })

          this.uploadFolders(this.filesForApi);
        } else {
          this.snackbarText = JSON.stringify(res.data["err"]);
          this.$emit('showSnackbar', this.snackbarText);
        }
      } catch (error) {
        this.snackbarText = JSON.stringify(error);
        this.showUploadingDialog = false;
      }
    },

    uploadNewTokenizedFolders(event) {
      let files = event.target.files;
      this.handleNewTokenizedFiles(files);
    },

    handleNewTokenizedFiles(files) {
      this.filesForApi = [];
      const arrayOfFiles = Array.from(files);

      arrayOfFiles.forEach((file, index, array) => {
        if (this.isValidTextFile(file.name)) {
          let fileReader = new FileReader();

          fileReader.onload = (fileLoadedEvent) => {
            const folderSegments = file.webkitRelativePath.split("/");
            const parentFolder = folderSegments[folderSegments.length - 2];
            const fileContent = fileLoadedEvent.target.result;

            const indexOfTokenizedWord = fileContent.indexOf('Tokenize');
            let content;
            if (indexOfTokenizedWord > -1) {
              content = fileContent.slice(indexOfTokenizedWord + 9, fileContent.length);
            } else {
              content = fileContent;
            }

            content = content.replace(/(\r\n|\n|\r)/gm, '\n/');

            this.filesForApi.push({
              folder: parentFolder,
              files: [{
                filename: file.name,
                content
              }]
            })

            if (index === array.length - 1) {
              this.uploadFolders(this.filesForApi);
            }
          };

          fileReader.readAsText(file, "UTF-8");
        }

      });
    },

    openFileInput(key) {
      switch (key) {
        case 'uploadNewNormalFolders': {
          this.$refs.newNormalFileInput.value = '';
          this.$refs.newNormalFileInput.click();
          break;
        }
        case 'uploadNewTokenizedFolders': {
          this.$refs.newTokenziedFileInput.value = '';
          this.$refs.newTokenziedFileInput.click();
          break;
        }
      }
    },
    clearData(option) {
      switch (option) {
        case 'all': {
          this.clearAllData();
          break;
        }

        case 'folders': {
          this.clearFolder();
          break;
        }

        default: {
          this.clearAllData();
        }
      }
    },
    clearAllData() {
      this.numberOfFiles = 0;
      this.$store.dispatch("clearAllData");
      this.$store.dispatch("clearSearchData");
      this.uploadedFiles = {};
    },
    clearFolder() {
      this.showClearDialog = true;
    },
    async clearSelectedFolder(selectedFolder) {
      this.showClearDialog = false;

      if (selectedFolder) {
        this.$store.dispatch('setApiPending', true);
        let res;
        try {
          res = await this.$api.preprocess.delete({folder: selectedFolder});

          if (res.status === 200) {
            this.$api.preprocess.prepare()
                .then((res) => {
                  if (res.status === 200 && res.data) {
                    this.$store.dispatch('addFolders', res.data);
                    this.$store.dispatch('setApiPending', false);
                  }
                })
                .catch((error) => {
                  this.snackbarText = error.statusText;
                  this.$emit('showSnackbar', this.snackbarText);
                  this.$store.dispatch('setApiPending', false);
                });
          } else {
            this.snackbarText = JSON.stringify(res.data["err"]);
            this.$emit('showSnackbar', this.snackbarText);
          }
        } catch (err) {
          this.snackbarText = JSON.stringify(err);
          this.$emit('showSnackbar', this.snackbarText);
        }
      }
    },
    // read normal files

    // call API
    async uploadFolders(contents) {
      this.numberOfFilesToUpload = contents.length;
      this.currentUploadingFileNumber = 0;
      this.hasPercent = true;
      this.showUploadingDialog = true;
      this.snackbarText = '';

      await contents.reduce(async (previousPromise, nextFolder) => {
        await previousPromise;
        return this.$api.preprocess.update(nextFolder)
            .then((res) => {
              if (!res.data.success) {
                this.snackbarText += res.data.err + '\n';
                this.$emit('showSnackbar', this.snackbarText);
              }
              this.currentUploadingFileNumber++;
            })
            .catch(() => {
              this.snackbarText = `Error uploading ${nextFolder.files[0].filename}`;
              this.$emit('showSnackbar', this.snackbarText);
              this.currentUploadingFileNumber++;
            });
      }, Promise.resolve());

      this.$store.dispatch('setApiPending', true);
      this.$api.preprocess.prepare()
          .then((res) => {
            if (res.status === 200 && res.data) {
              this.$store.dispatch('addFolders', res.data);
              this.showUploadingDialog = false;
              this.$store.dispatch('setApiPending', false);
            }
          })
          .catch((error) => {
            this.showUploadingDialog = false;
            console.error(error);
            this.$store.dispatch('setApiPending', false);
          })
    },
    isValidTextFile(filename) {
      const fileSegments = filename.split('.');
      const fileExtension = fileSegments[fileSegments.length - 1];
      return fileExtension.toLowerCase() === 'txt';
    }
  },
};
</script>
<style scoped>
.v-sheet.v-list {
  padding: 0;
}

.v-list-item:hover {
  background: #272727;
  color: white !important;
  cursor: pointer;
}
</style>
