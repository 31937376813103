<template>
  <div class="text-center">
    <v-snackbar
        v-model="showSnackbar"
        :timeout="disableTimeout ? -1 : timeout"
        @input="$emit('closeSnackBar')"
    >
      <span style="white-space: pre-line">{{ text }}</span>
      <div></div>

      <template v-slot:action="{ attrs }">
        <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="$emit('closeSnackBar')"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  data: () => ({
    timeout: 30000,
    showSnackbar: false
  }),
  props: {
    text: {
      type: String,
      required: true,
      default: 'Server error, please try again'
    },
    snackbar: {
      type: Boolean,
      default: false
    },
    disableTimeout: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    snackbar(newVal) {
      this.showSnackbar = newVal;
    }
  }
}
</script>
