import axios from "axios";

export default {
    async preprocess(payload) {
        return await axios.post("http://chunom.kimtudien.com.vn:8888/preprocess/", payload)
    },
    async prepare() {
        return await axios.get(process.env.VUE_APP_BASE_API + "prepare/")
    },
    async update(payload) {
        return await axios.post(process.env.VUE_APP_BASE_API + "prepare/", payload)
    },
    async delete(payload) {
        return await axios.delete(process.env.VUE_APP_BASE_API + "prepare/", {
            data: payload,
            headers: {
                'Content-Type': 'application/json'
            }
        })
    },
    async statistics(payload) {
        return await axios.post(process.env.VUE_APP_BASE_API + "statistics/", payload)
    },
    async contexts(payload) {
        return await axios.post(process.env.VUE_APP_BASE_API + "contexts/", payload)
    },
    async get_content(payload) {
        return await axios.post(process.env.VUE_APP_BASE_API + "contents/", payload)
    },
    async tfidf(payload) {
        return await axios.post(process.env.VUE_APP_BASE_API + "tfidf/", payload)
    },
    async collocations(payload) {
        return await axios.post(process.env.VUE_APP_BASE_API + "collocation/", payload)
    }
}
