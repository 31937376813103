import axios from "axios";

axios.interceptors.request.use(
  async function (config) {
      config.headers["Authorization"] = "Bearer " + "yoyoyo";
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
);

axios.interceptors.response.use(
  async function (response) {
      return response;
    },
    async function (error) {
      switch (error.response.status) {
        case 401:
          break;
          // case 403:
          //     return _instance.$toast.error("User role don't have permissions!");
        default:
          break;
      }
      return Promise.reject(error);
    }
);
