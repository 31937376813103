<template>
  <v-app>
    <Sidebar :drawer="drawer" @updateDrawerState="updateDrawerState"></Sidebar>
    <AppBar @toggleSidebar="drawer = !drawer" @showSnackbar="showInfoSnackbar($event)"></AppBar>
    <v-main>
      <ProgressBar class="app-progress-bar"></ProgressBar>
      <v-container class="main-container" fluid>
        <router-view />
      </v-container>
      <InfoSnackbar :snackbar="showSnackbar" :text="snackbarText" @closeSnackBar="closeSnackBar"
        :disable-timeout="true">
      </InfoSnackbar>
    </v-main>
  </v-app>
</template>

<script>
import Sidebar from "./views/Sidebar";
import AppBar from "./views/Appbar";
import ProgressBar from "./views/ProgressBar";
import InfoSnackbar from "./components/reuseable/info-snackbar";

export default {
  name: "App",
  components: {ProgressBar, Sidebar, AppBar, InfoSnackbar },
  data: () => ({
    drawer: true,
    showSnackbar: false,
    snackbarText: '',
  }),
  methods: {
    updateDrawerState(value) {
      this.drawer = value;
    },
    closeSnackBar() {
      this.showSnackbar = false;
      this.snackbarText = '';
    },
    showInfoSnackbar(text) {
      this.snackbarText = text;
      this.showSnackbar = true;
    }
  },
};
</script>
<style>
  .main-container {
    height: calc(100vh - 64px);
    overflow: hidden;
  }
  .app-progress-bar {
    position: fixed;
  }
</style>
