<template>
  <v-progress-linear
      indeterminate
      color="primary darken-2"
      v-if="showProgressBar"
  ></v-progress-linear>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters([
        'getApiPending'
    ]),

    showProgressBar() {
      const allPendings = [
          this.getApiPending
      ];

      return !allPendings.some((pending) => !pending);

    }
  },
  data: function () {
    return {
      show: true,
    }
  },
  watch: {
    getApiPending: {
      immediate: true,
      handler() {

      }
    }
  }
}
</script>
