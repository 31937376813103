import preprocess from "./preprocess";

const apiUrlManager = {
  preprocess,
};

export default {
  install(Vue) {
    Vue.prototype.$api = apiUrlManager;
  },
};
