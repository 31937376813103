import Vue from 'vue';
import App from './App.vue';

import Vuex from 'vuex';
import store from './store';
import router from './router';

import vuetify from './plugins/vuetify';
import './assets/css/style.css';
import './assets/css/vuelify.css';

import helper from './helper';
import api from './api';
import './config/interceptors-axios';

Vue.config.productionTip = false

Vue.use(api)
Vue.use(helper)

new Vue({
  router,
  vuetify,
  Vuex,
  store,
  render: h => h(App)
}).$mount('#app')
